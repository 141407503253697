<template>
	<div>
		<a-divider orientation="left" class="divider">
			Comportamento dos pedidos
		</a-divider>
		<p>Selecione o modo que os pedidos serão atendidos por vocês</p>
		<a-row>
			<a-radio-group
				v-model="merchant.single_request"
				button-style="solid"
				@change="changeRequestPaymentMode"
			>
				<a-radio-button :value="false">
					Comanda
				</a-radio-button>
				<a-radio-button :value="true">
					Único pedido
				</a-radio-button>
			</a-radio-group>
		</a-row>

		<p style="margin-top: 24px;">
			Os clientes podem oferecer gorjeta atravês do app?
		</p>
		<a-row>
			<a-radio-group
				v-model="merchant.accept_tip"
				button-style="solid"
				@change="changeAcceptTip"
			>
				<a-radio-button :value="true">
					Sim
				</a-radio-button>
				<a-radio-button :value="false">
					Não
				</a-radio-button>
			</a-radio-group>
		</a-row>

		<a-divider orientation="left" class="divider" style="margin-top: 32px;">
			Sobre o restaurante
		</a-divider>
		<p>Atualize as informações que serão apresentadas para seus clientes</p>
		<a-row>
			<a-col :span="15">
				<a-form :form="form" @submit.prevent="handleSubmit">
					<a-row :gutter="16">
						<a-col :span="12">
							<a-form-item label="Nome" has-feedback>
								<a-input
									v-decorator="[
										'name',
										{
											initialValue: merchant.name,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe um nome!',
												},
											],
										},
									]"
									placeholder="Informe o nome para o seu usuário"
								/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item
								label="Categoria principal"
								has-feedback
							>
								<a-select
									v-decorator="[
										'main_category_id',
										{
											initialValue:
												merchant.main_category_id,
											rules: [
												{
													required: true,
													message:
														'Por favor, selecione uma especialidade!',
												},
											],
										},
									]"
									placeholder="Selecione a especialidade do seu restaurante"
									class="select"
									show-search
									option-filter-prop="children"
									:filter-option="filterOption"
									@change="handleChangeMainCategory"
								>
									<a-select-option
										v-for="category in categories"
										:key="category.id"
									>
										{{ category.name }}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row>
						<a-col>
							<a-form-item label="Outras categorias">
								<a-select
									v-model="selectedCategories"
									mode="multiple"
									placeholder="Selecione outras categorias atendidas"
									option-filter-prop="children"
									:filter-option="filterOption"
								>
									<a-select-option
										v-for="category in extraCategories"
										:key="category.id"
									>
										{{ category.name }}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row>
						<a-col>
							<a-form-item label="Bio" has-feedback>
								<a-textarea
									v-decorator="[
										'presentation',
										{
											initialValue: merchant.presentation,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe um nome!',
												},
											],
										},
									]"
									placeholder="Faça um pequena apresentação do seu restaurante para os clientes do aplicativo."
									:auto-size="{ minRows: 1, maxRows: 3 }"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="14">
							<a-form-item label="Cashback">
								<a-slider
									v-decorator="[
										'cashback_percentage',
										{
											initialValue:
												(merchant.cashback_percentage ||
													1) * 10,
										},
									]"
									:marks="cashbackMask"
									:tooltip-visible="false"
									:step="10"
									:min="10"
									:max="100"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<a-form-item label="Nº de pedidos entre">
								<a-input-group compact>
									<a-input-number
										v-model="merchant.order_number_min"
										style="width: 35%; text-align: center;"
										placeholder="Início"
										:min="1"
									/>
									<a-input
										style="
											width: 35px;
											border-left: 0;
											pointer-events: none;
											background: #fff;
										"
										placeholder="~"
										disabled
									/>
									<a-input-number
										v-model="merchant.order_number_max"
										style="
											width: 35%;
											text-align: center;
											border-left: 0;
										"
										placeholder="Fim"
										:min="minMax"
									/>
								</a-input-group>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="Raio de atuação">
								<a-input-number
									v-decorator="[
										'actuation_radius',
										{
											initialValue:
												merchant.actuation_radius,
										},
									]"
									style="width: 60%; margin-right: 8px;"
									placeholder=""
									:min="5"
								/>
								<span style="color: rgba(0, 0, 0, 0.5);">
									metros
								</span>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row
						type="flex"
						justify="center"
						style="padding: 24px 0 0 0;"
					>
						<a-col>
							<a-form-item>
								<a-button
									type="primary"
									html-type="submit"
									icon="save"
									block
								>
									Salvar alterações
								</a-button>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-col>
			<a-col :span="9">
				<a-row
					:style="{ minHeight: '180px', padding: '10px 0 15px 0' }"
				>
					<span class="avatar-label">Logo:</span>
					<div
						class="ant-upload-preview"
						@click="$refs.modal.setShowModal('50%')"
					>
						<div class="mask">
							<a-icon type="edit" />
						</div>
						<img :src="preview" />
					</div>
				</a-row>
			</a-col>
		</a-row>
		<a-divider orientation="left" class="divider" style="margin-top: 32px;">
			Redes Sociais
		</a-divider>
		<p>Informe as redes sociais que seu restaurante dispõe</p>
		<a-form layout="inline" :form="socialForm">
			<a-form-item>
				<a-input-group compact>
					<a-select
						v-model="network"
						default-value="facebook"
						style="width: 250px;"
					>
						<a-select-option
							v-for="net in socialNets"
							:key="net.name"
						>
							{{ net.link }}
						</a-select-option>
					</a-select>
					<a-input
						v-model="link"
						style="width: 170px; border-left: 0;"
						@pressEnter="handleAddSocialNetwork"
					/>
					<a-button
						type="primary"
						icon="plus"
						:disabled="link.length === 0"
						@click.prevent="handleAddSocialNetwork"
					>
						Adicionar
					</a-button>
				</a-input-group>
			</a-form-item>
			<a-form-item v-if="link.length > 0">
				<span style="font-weight: bold;">Link:</span> {{ networkLink }}
			</a-form-item>
		</a-form>
		<a-divider orientation="center" class="divider" :dashed="true" />
		<a-list
			v-if="loading"
			item-layout="horizontal"
			:data-source="[1, 2, 3]"
		>
			<a-list-item slot="renderItem" key="item.title" slot-scope="item">
				<a-skeleton
					:loading="true"
					:title="false"
					:paragraph="{ rows: 1, width: ['85%'] }"
					active
				>
					{{ item }}
				</a-skeleton>
			</a-list-item>
		</a-list>
		<a-list
			v-else
			item-layout="horizontal"
			:data-source="merchant.social_networks"
			:locale="ptBR"
		>
			<a-list-item
				slot="renderItem"
				slot-scope="item"
				style="padding: 8px 0;"
			>
				<ActionsButtons
					slot="actions"
					:duplicate="false"
					:slide="false"
					:edit="false"
					description="link"
					style="color: rgba(0, 0, 0, 0.7);"
					@delete="linkDelete(item.id)"
				/>
				<a-list-item-meta>
					<span slot="title">{{ item.link }}</span>
				</a-list-item-meta>
			</a-list-item>
		</a-list>
		<AvatarModal ref="modal" @ok="setLogo" />
	</div>
</template>

<script>
import { AvatarModal } from '../../components'
import { stateList } from '@/assets/emitterStateList'
import { mapGetters, mapActions } from 'vuex'
import { ActionsButtons } from '@/modules/partner/components'

const cashbackMask = {
	10: '1%',
	20: '2%',
	30: '3%',
	40: '4%',
	50: '5%',
	60: '6%',
	70: '7%',
	80: '8%',
	90: '9%',
	100: '10%',
}

const socialNets = [
	{
		name: 'facebook',
		link: 'https://www.facebook.com/',
	},
	{
		name: 'instagram',
		link: 'https://www.instagram.com/',
	},
	{
		name: 'twitter',
		link: 'https://www.twitter.com/',
	},
]

export default {
	name: '',
	components: {
		AvatarModal,
		ActionsButtons,
	},
	data() {
		return {
			form: this.$form.createForm(this),
			socialForm: this.$form.createForm(this),
			categoriesForm: this.$form.createForm(this),
			stateList,
			merchant: {},
			categories: [],
			mainCategory: null,
			selectedCategories: [],
			cashbackMask,
			socialNets,
			network: 'facebook',
			link: '',
			ptBR: { emptyText: 'Nenhuma rede social adicionada' },
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		preview() {
			const { logo } = this.merchant
			return logo ? logo : 'img/placeholder-image.png'
		},
		extraCategories: function () {
			return this.categories.filter((e) => e.id !== this.mainCategory)
		},
		minMax: function () {
			return this.merchant.order_number_min === ''
				? 1
				: this.merchant.order_number_min
		},
		networkLink: function () {
			const net = socialNets.find((net) => net.name === this.network)
			return net !== null ? `${net.link}${this.link}` : ''
		},
	},
	watch: {
		merchant: function () {
			this.selectedCategories =
				this.merchant !== undefined ? this.merchant.categories_ids : []
		},
	},
	beforeMount() {
		this.getData()
	},
	methods: {
		...mapActions('merchants', [
			'getMerchant',
			'updateMerchant',
			'updateLogo',
			'getSocialNetworks',
			'addSocialNetwork',
			'deleteSocialNetworks',
		]),
		async getData() {
			const { merchant, categories } = await this.getMerchant(
				this.selectedMerchantId
			)

			this.merchant = merchant
			this.categories = categories
			this.mainCategory = merchant.main_category_id
		},
		async setLogo(img) {
			const payload = {
				merchantId: this.selectedMerchantId,
				img,
			}

			this.$message.loading({
				content: 'Atualizando imagem...',
				key: 'updatable',
			})
			this.merchant = await this.updateLogo(payload)
			this.$message.success({
				content: 'Imagem atualizada',
				key: 'updatable',
				duration: 4,
			})
		},
		handleSubmit() {
			this.form.validateFields(async (err, values) => {
				if (!err) {
					const { main_category_id } = values

					values.categories_ids = [main_category_id]
					values.cashback_percentage /= 10
					values.order_number_min = this.merchant.order_number_min
					values.order_number_max = this.merchant.order_number_max
					delete values.main_category_id

					if (this.selectedCategories.length > 0) {
						values.categories_ids.push(...this.selectedCategories)
					}

					const payload = {
						merchantId: this.selectedMerchantId,
						data: { ...values },
					}

					this.$message.loading({
						content: 'Atualizando informações...',
						key: 'updatable',
					})
					this.merchant = await this.updateMerchant(payload)
					this.$message.success({
						content: 'Informações atualizadas',
						key: 'updatable',
						duration: 4,
					})
				}
			})
		},
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			)
		},
		handleChangeMainCategory(value) {
			this.mainCategory = value
			this.selectedCategories = this.selectedCategories.filter(
				(e) => e !== value
			)
		},
		Categories(value) {
			this.selectedCategories = value
		},
		async changeRequestPaymentMode(e) {
			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					data: {
						single_request: e.target.value,
					},
				}

				this.$message.loading({
					content: 'Atualizando informações...',
					key: 'updatable',
				})
				this.merchant = await this.updateMerchant(payload)
			} catch (error) {
				//
			} finally {
				this.$message.success({
					content: 'Informações atualizadas',
					key: 'updatable',
					duration: 4,
				})
			}
		},
		async changeAcceptTip(e) {
			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					data: {
						accept_tip: e.target.value,
					},
				}

				this.$message.loading({
					content: 'Atualizando informações...',
					key: 'updatable',
				})

				this.merchant = await this.updateMerchant(payload)
			} catch (error) {
				//
			} finally {
				this.$message.success({
					content: 'Informações atualizadas',
					key: 'updatable',
					duration: 4,
				})
			}
		},
		async handleAddSocialNetwork() {
			if (this.link.length === 0) return

			const payload = {
				merchantId: this.selectedMerchantId,
				data: [
					{
						name: this.network,
						link: this.networkLink,
					},
				],
			}

			try {
				this.loading = true
				await this.addSocialNetwork(payload)
				this.merchant.social_networks = await this.getSocialNetworks(
					this.selectedMerchantId
				)
			} catch (error) {
				//
			} finally {
				this.link = ''
				this.loading = false
			}
		},
		async linkDelete(socialNetworkId) {
			const payload = {
				merchantId: this.selectedMerchantId,
				networkIds: [socialNetworkId],
			}

			try {
				this.loading = true
				await this.deleteSocialNetworks(payload)
				this.merchant.social_networks = await this.getSocialNetworks(
					this.selectedMerchantId
				)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../config/settingsStyles.less');

.divider {
	margin: 24px 0 8px 0px;
	color: #777;
	font-size: 15px;
}
</style>
